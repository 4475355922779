import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../LoginForm/LoginForm.css'

const LoginForm = () => {
    const [formData, setFormData] = useState({
        accountnumber: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    return (
        <>
            <section className="login-form">
                <div className="first-row">
                    <div className="row">
                        <div className="col-md-2 text-lg-start">
                            <div className="contact-us">
                                <button className="btn contact-us-btn"><img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/contact-btn.png"} className="contact-img" alt="..." /></button>
                                <div className='contact-us-text'>
                                    <p>Contact us</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mx-auto">
                            <div className="game-boy-main justify-content-center mt-3">
                                <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Gamer-Boy.png"} className="game-boy-img" alt="..." />
                                <p className="welcome-text">Welcome</p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className='close-btn-main text-end'>
                                <button className="btn close-btn"><img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/close.png"} className="close-img" alt="..." /></button>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-5 mx-auto">
                            <div className='logged-form'>
                                <div className="text-center">
                                    <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/logged.png"} className="logged-img" alt="..." />
                                </div>
                                {/* Form */}
                                <div className="text-holder-login">
                                    <h5 className='title-form'>Response : Please now login</h5>
                                    <div className='mt-4'>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <label>
                                                        Account Number  :
                                                    </label>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" name="accountnumber" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-6 col-12 text-end'>
                                                    <label >
                                                        Password    :
                                                    </label>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    {/* <input
                                                        type="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                    /> */}
                                                    <div className="input-group">
                                                        <input type="password" className="form-control" name="password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mt-md-5 mt-lg-0'>
                                                <Link to="/register"><button type="submit" className='start-btn-class mb-5 mt-5'>Start</button></Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginForm;