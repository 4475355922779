// import logo from '../../../casino _ Circle-03.svg'
import coins from '../../../casino _ coins-04.svg'
import '../TarSpinner/TarSpinner.css';
import logo from './casino-_-circle (1)-modified (1).png'
import roundimg from '../TarSpinner/casino-_-circle (1)-modified.png'
import pointer from '../TarSpinner/pointer-02.svg'

function TarSpinner() {
  return (
    <div className="fun-target-spinner ">
      <header className="App-header-class">
        <img src={logo} className="App-logo" alt="logo" />
        <div className='coins-main-img'>
          <img src={coins} className="app-coins" alt="coins" />
        </div>
        <div className='round-center-img'>
          <img src={roundimg} className="center-img" alt="center-img" />
        </div>
        <div className='pointer-img-main'>
          <img src={pointer} className="pointer-img" alt="pointer" />
        </div>
      </header>
    </div>
  );
}

export default TarSpinner;




