import React from 'react'
import { Route, Routes} from 'react-router-dom'
import LoginPage from '../LoginPage/LoginPage'
import Registration from '../Registration/Registration'
import HomePage from '../HomePage/HomePage'
import FunMain from '../FunRoulette/FunMain/FunMain'
import FunSpinner from '../FunRoulette/FunSpinner/FunSpinner'
import FunTarget from '../FunTarget/FunTarget'
import MainFunDarBhar from '../FunDarBhar/MainFunDarBhar/MainFunDarBhar.js'
import FunTriple from '../FunTriple/FunTriple.js'
import TarSpinner from '../FunTarget/TarSpinner/TarSpinner.js'
import ReactSpinner from '../FunTarget/TarSpinner/ReactSpinner.js'


const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<LoginPage />}></Route>
        <Route path='/register' element={<Registration />}></Route>
        <Route path='/homepage' element={<HomePage />}></Route>
        <Route path='/fun-roulette' element={<FunMain />}></Route>
        <Route path='/spinner' element={<FunSpinner />}></Route>
        <Route path='/fun-target' element={<FunTarget />}></Route>
        <Route path='/fun-darbhar' element={<MainFunDarBhar />}></Route>
        <Route path='/fun-triple' element={<FunTriple />}></Route>
        <Route path='/target-spinner' element={<TarSpinner />}></Route>
        <Route path='/react-spin' element={<ReactSpinner />}></Route>
      </Routes>
    </>
  )
}

export default AllRoutes