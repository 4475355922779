import React from 'react';
import FunTargetText from './FunTargetText/FunTargetText';


const FunTarget = () => {
  return (
      <div>
        <FunTargetText />
      </div>
  )
}

export default FunTarget;
