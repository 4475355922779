import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CheckAuthontiCity/CheckAuthontiCity.css'

const CheckAuthontiCity = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setIsChecked1(!isChecked1);
    };

    return (
        <>
            <section className="Check-authonticity ms-4">
                <div className=' row'>
                    <div className='col-md-12'>
                        <div className='title-check text-center mt-5'>
                            <h5>Check Authenticity</h5>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='sub-title-check'>
                            <div className='text-holder-receivable'>
                                <p>Receivables</p>
                            </div>
                            <div className='text-holder-receiv'>
                                <p>Receive</p>
                            </div>
                            <div className='text-holder-reject'>
                                <p>Reject</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='first-section'>
                            <div className='check-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/check.png"} className="check-img" alt="..." />
                            </div>
                            <div className='text-holder-account-no'>
                                <p>Account No.</p>
                            </div>
                            <div className='text-holder-amount'>
                                <p>Amount</p>
                            </div>
                            <div className='text-holder-type'>
                                <p>Type</p>
                            </div>
                            <div className='select-check-main'>
                                <div className='select-all-main'>
                                    <div className='select-all-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/homepage/select.png"} className="select-img" alt="..." />
                                    </div>
                                    <div className='check-chekbox'>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className='ms-2'>
                                            Select all
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='sub-title-check'>
                            <div className='text-holder-receivable'>
                                <p>Transferables</p>
                            </div>
                            <div className='text-holder-reject'>
                                <p>Reject</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='first-section'>
                            <div className='check-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/check.png"} className="check-img" alt="..." />
                            </div>
                            <div className='text-holder-account-no'>
                                <p>Account No.</p>
                            </div>
                            <div className='text-holder-amount'>
                                <p>Amount</p>
                            </div>
                            <div className='text-holder-type'>
                                <p>Type</p>
                            </div>
                            <div className='select-check-main'>
                                <div className='select-all-main'>
                                    <div className='select-all-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/homepage/select.png"} className="select-img" alt="..." />
                                    </div>
                                    <div className='check-chekbox'>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className='ms-2'>
                                            Select all
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='ok-btn-main' >
                            <div className='ok-btn-img' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/ok.png"} className="ok-img" alt="..." />
                            </div>
                            <div className='text-holder-ok'>
                                <button className='ok-btn'>Ok</button>
                            </div>
                        </div>
                    </div>
                    <div className='response-text ms-5 mt-4'>
                        <p>Response -:</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CheckAuthontiCity;