import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunDarBharButton/FunDarBharButton.css'

const FunDarBharButton = () => {
    return (
        <>
            <div className="fun-darbhar-button">
                {/* Round */}
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='red-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/red.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>10</p>
                                </div>
                            </div>
                        </div>
                        <div className='green-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/green.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>20</p>
                                </div>
                            </div>
                        </div>
                        <div className='yellow-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/yellow.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>50</p>
                                </div>
                            </div>
                        </div>
                        <div className='dr-blue-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/dr-blue.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>100</p>
                                </div>
                            </div>
                        </div>
                        <div className='dark-blue-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/dark-blue.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>500</p>
                                </div>
                            </div>
                        </div>
                        <div className='light-blue-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/light-blue.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>1000</p>
                                </div>
                            </div>
                        </div>
                        <div className='blue-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/light-blue (2).png"} className="round-img " />
                                <div className='number-class'>
                                    <p>5000</p>
                                </div>
                            </div>
                        </div>
                        <div className='orange-round-and-card' >
                            <div className='round-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/orange.png"} className="round-img " />
                                <div className='number-class'>
                                    <p>5000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Cards */}
                <div className='row'>
                    <div className='black-img-main-first'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/black9.png"} className="cards-img " />
                    </div>
                    <div className='black-img-main-second'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/red9.png"} className="cards-img " />
                    </div>
                    <div className='black-img-main-third'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/red3.png"} className="cards-img " />
                    </div>
                    <div className='black-img-main-four'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/red6.png"} className="cards-img " />
                    </div>
                    <div className='black-img-main-five'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/black8.png"} className="cards-img " />
                    </div>
                </div>
                {/* Desktop view */}
                <div className='desktop-view-btn'>
                    <div className='row mt-5'>
                        <div className='col-md-2'>
                            <div className='fun-take-button'>
                                <div className='sub-fun-take'>
                                    <button className='fun-ab-button'>Take</button>
                                    <div className='take-btn-img'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/takeb.png"} className="ellips-img " />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='bet-time-over'>
                                <button className='bet-time-over-button'>Bet Time Over</button>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className='fun-exit-button'>
                                <div className='sub-fun-exit'>
                                    <button className='fun-ab-button'>Exit</button>
                                    <div className='take-btn-img'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/close.png"} className="ellips-img " />
                                    </div>
                                </div>
                            </div>
                            <div className='fun-take-bet-ok-button'>
                                <div className='sub-fun-bet-ok'>
                                    <button className='fun-ab-button'>Bet ok</button>
                                    <div className='take-btn-img'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/betok.png"} className="ellips-img " />
                                    </div>
                                </div>
                            </div>
                            <div className='fun-total-bet-button'>
                                <p className='total-bet-text'>total bet</p>
                                <button className='fun-ab-button'>0</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tab view */}
                <div className='tab-view-button mt-3'>
                    <button className='btn take-btn me-2'>take</button>
                    <button className='btn take-btn me-2'>Bet Time Over</button>
                    <button className='btn take-btn me-2'>bet ok</button>
                    <button className='btn take-btn me-2'>exit</button>
                    <button className='btn take-btn me-2'>0</button>
                </div>
            </div>
        </>
    )
}

export default FunDarBharButton;