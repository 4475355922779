import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunGame/FunGame.css'

const FunGame = () => {
    return (
        <>
            <section className="fun-game">
                <div className='contanier-fluid'>
                    {/* First Row */}
                    <div className='row mt-3'>
                        <div className='col-md-1'>
                            <div className='last-btn text-end'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-left-bg.png"} className="lastline-img" alt="..." />
                                <div className='back-btn-main'>
                                    <button className='btn back-btn-class'>0.00</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='last-btn text-center'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/roulette.png"} className="title-roulette-img" alt="..." />
                                <div className='back-btn-main'>
                                    <button className='btn roulette-btn-class'>Fun Roulette</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className='last-btn text-end'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-right-bg.png"} className="lastline-img" alt="..." />
                                <div className='back-btn-main '>
                                    <button className='btn back-btn-class'>0</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Second row */}
                    <div className='second-row'>
                        <div className='row mt-5'>
                            <div className='col-md-2'>
                                <div className='last-btn text-end'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-left-bg.png"} className="lastline-img" alt="..." />
                                    <div className='back-btn-main'>
                                        <button className='btn back-btn-second-class'>0.22</button>
                                    </div>
                                </div>
                                <div className='d-flex ms-4'>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/oran.png"} className="round-img" alt="..." />
                                        <div className='one-btn-main'>
                                            <button className='btn back-btn-class'>1</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/blue.png"} className="round-img" alt="..." />
                                        <div className='second-btn-main'>
                                            <button className='btn back-btn-class'>5</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/red.png"} className="round-img" alt="..." />
                                        <div className='third-btn-main'>
                                            <button className='btn back-btn-class'>10</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/yell.png"} className="round-img" alt="..." />
                                        <div className='four-btn-main'>
                                            <button className='btn back-btn-class'>50</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex ms-4'>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/cya.png"} className="round-img" alt="..." />
                                        <div className='five-btn-main'>
                                            <button className='btn back-btn-class'>100</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/vio.png"} className="round-img" alt="..." />
                                        <div className='six-btn-main'>
                                            <button className='btn back-btn-class'>500</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/oran.png"} className="round-img" alt="..." />
                                        <div className='seven-btn-main'>
                                            <button className='btn back-btn-class'>1000</button>
                                        </div>
                                    </div>
                                    <div className='last-btn'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/dark-blue.png"} className="round-img" alt="..." />
                                        <div className='eight-btn-main'>
                                            <button className='btn back-btn-class'>5000</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='text-center'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/Fun-table.png"} className="spinner-img" alt="..." />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='last-btn'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-right-bg.png"} className="lastline-img" alt="..." />
                                    <div className='white-right-btn'>
                                        <button className='btn back-btn-second-class'><span>15</span><span>1</span><span>4</span><span>18</span><span>18</span></button>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='last-btn-second'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-left-bg.png"} className="take-btn" alt="..." />
                                        <div className='white-right-btn'>
                                            <button className='btn take-btn-class'>take</button>
                                        </div>
                                    </div>
                                    <div className='last-btn-second ms-2'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-right-bg.png"} className="take-btn" alt="..." />
                                        <div className='bet-ok-btn '>
                                            <button className='btn take-btn-class'>bet ok</button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='last-btn-second'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/cancel.png"} className="cancel-img" alt="..." />
                                        <div className='cancel-right-btn'>
                                            <button className='btn take-btn-class'>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunGame;