import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../MainFunDarBhar/MainFunDarBhar.css'
import ScoreWinner from '../ScoreWinner/ScoreWinner.js';
import FunDarBharButton from '../FunDarBharButton/FunDarBharButton.js';

const MainFunDarBhar = () => {
    return (
        <>
            <section className="main-fun-darbhar">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <ScoreWinner />
                        </div>
                        <div className='col-md-12'>
                            <FunDarBharButton />
                        </div>
                        <div className='col-md-12'>
                            <div className='women-img-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/woman.png"} className="women-img" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainFunDarBhar;