import React from 'react';
import './App.css';
import AllRoutes from '../src/component/AllRoutes/AllRoutes.js'



function App() {;
  return (  
    <div className="App">
      <AllRoutes/>
    </div>
  );
}

export default App;
