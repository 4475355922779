import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import '../FunSpinner/FunSpinner.css'


const FunSpinner = () => {

    const data = [
        { option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
        { option: '1' },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
        { option: '0' },
        { option: '1' },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
        { option: '0' },
        { option: '1' },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
        { option: '0' },
        { option: '1' },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
    ];

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * data.length);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
    };

    return (
        <>
            <div className='col-md-12'>
                <div className='spinner'>
                    <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={data}
                        backgroundColors={['#000', '#df3428']}
                        textColors={['#ffffff']}
                        outerBorderColor={['#FFFF4B']}
                        innerBorderColor={['#FFFF4B']}
                        spinDuration={[0.05]}
                        onStopSpinning={() => {
                            setMustSpin(false);
                        }}
                    />
                    {/* <div className='tripe-img-main'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/tripe.png"} className="tripe-img" alt="..." />
                    </div> */}
                </div>
            </div>
            <div className='col-md-12 text-center'>
                <div className="common-button-main">
                    <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Pink.png"} className="single-img" alt="..." />
                    <div className='sub-common-button'>
                        <button className="first-line-button" onClick={handleSpinClick} >spin</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FunSpinner;



