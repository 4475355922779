import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../ScoreWinner/ScoreWinner.css'
import BackGroundGlow from '../../LoginPage/Animation/BackGroundGlow';

const ScoreWinner = () => {
    return (
        <>
            <section className="score-winner">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex justify-content-between' >
                                <div>
                                    <p className='score-text mt-5'>Score</p>
                                    <div className='score-button-main'>
                                        <div className='score-button'>
                                            <button className='score-btn-class'><img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/score-bg.png"} className="score-img" /></button>
                                        </div>
                                        <p className='score-number'>0.00</p>
                                        <div className='left-bg-glow'>
                                            <BackGroundGlow/>
                                        </div>
                                        <div className='right-bg-glow'>
                                            <BackGroundGlow/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='score-text mt-5'>Winner</p>
                                    <div className='score-button-main'>
                                        <div className='score-button'>
                                            <button className='score-btn-class'><img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/score-bg.png"} className="score-img" /></button>
                                        </div>
                                        <p className='score-number'>0.00</p>
                                        <div className='left-bg-glow'>
                                            <BackGroundGlow/>
                                        </div>
                                        <div className='right-bg-glow'>
                                            <BackGroundGlow/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Second row */}
                        <div className='row mt-5'>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                            <div className='col-md-1'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/A.png"} className="cards-img" alt="..." />
                            </div>
                        </div>
                        {/* Third row */}
                        <div className='row text-center'>
                            <div className='col-md-5 mt-5'>
                                <div className='main-class-title'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/firstimg.png"} className="title-img" alt="..." />
                                    <div className='love-img-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/love.png"} className="love-img " />
                                    </div>
                                    <div className='love-img-square'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/squre.png"} className="love-img " />
                                    </div>
                                    <div className='love-img-threecard'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/threecard.png"} className="love-img " />
                                    </div>
                                    <div className='love-img-badam'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/badam.png"} className="love-img " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 mt-4'>
                                <div className='main-class-title'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/secondimg.png"} className="title-img" alt="..." />
                                    <div className='red-img-card'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/red-card.png"} className="red-img " />
                                    </div>
                                    <div className='black-img-card'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/black-card.png"} className="black-img " />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 mt-5'>
                                <div className='main-class-title'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/thirdimg.png"} className="title-img" alt="..." />
                                    <div className='a-to-6'>
                                        <p>a to 6</p>
                                    </div>
                                    <div className='seven-text'>
                                        <p>7</p>
                                    </div>
                                    <div className='eight-to-k'>
                                        <p>8 to k</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row text-center'>
                            <div className='col-md-2'></div>
                            <div className='col-md-3 mt-4'>
                                <div className='bg-score'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/right-left.png"} className="right-bg-card-img" />
                                    <div className='first-card-img-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/10-fl.png"} className="first-card-img" />
                                    </div>
                                    <div className='text-center'>
                                        <p className='andhar-text'>Andar</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2 mt-2'>
                                <div className='bg-score'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/center.png"} className="right-bg-card-img" />
                                    <div className='center-card-img-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/blur-bg.png"} className="first-card-center-img" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 mt-4'>
                                <div className='bg-score'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/right-left.png"} className="right-bg-card-img" />
                                    <div className='first-card-img-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fundarbhar/7-fl.png"} className="first-card-img" />
                                    </div>
                                    <div className='text-center'>
                                        <p className='andhar-text'>Bahar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ScoreWinner;