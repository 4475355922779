import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../Animation/119448-background-glow.json';
import '../Animation/BackGroundGlow.css';

function BackGroundGlow(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <section className='main'>
            <div className='BackGround-Glow'>
                <Lottie className="bg-lottie"
                options={defaultOptions}    
                />       
        </div>
      </section>
    );
}
export default BackGroundGlow;