import React, { useEffect } from 'react';
import BackGround from '../LoginPage/BackGround/BackGround.js';
import LoginForm from './LoginForm/LoginForm';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const LoginPage = () => {
    const handle = useFullScreenHandle();

    useEffect(() => {
        handle.enter();
    }, [])
    return (
        <FullScreen handle={handle}>
            <div>
                <BackGround />
                <LoginForm />
            </div>
        </FullScreen>
    )
}

export default LoginPage;