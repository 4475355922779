import React, { useState } from "react";
import '../RegistrationForm/RegistrationForm.css'
import { Link } from "react-router-dom";

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        dob: '',
        occupation: '',

        address: '',
        country: '',
        state: '',

        city: '',
        Pincode: '',
        type: '',

        phone: '',
        email: '',

        password: '',
        cpassword: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    return (
        <>
            <section className="registration-form">
                <div className="container-fluid">
                    <div className="register-bg">
                        <div className="register-sub mt-lg-5 mt-md-4">
                            <img src={process.env.PUBLIC_URL + "assets/Image/registration/registerbg.png"} className="registerbg-img" alt="..." />
                        </div>
                        <div className="register-form">
                            <div className="register-title">
                                <h2>Registration</h2>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="row mt-lg-4 mt-xl-5 mt-md-4 ">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>name</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start ">
                                            <label>dob</label>
                                            <div className="input-group">
                                                <input type="date" className="form-control" name="dob" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>occupation</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="occupation" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>address</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>country</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="country" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>state</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="state" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>city</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name="city" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>pincode</label>
                                            <div className="input-group">
                                                <input type="pincode" className="form-control" name="city" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <div className="col-md-12 text-start">
                                                <label>type</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" name="city" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>phone</label>
                                            <div className="input-group">
                                                <input type="phone" className="form-control" name="city" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>email</label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" name="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="col-md-12 text-start">
                                            <label>Password</label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" name="password" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        {/* <div className="col-md-12 text-start">
                                            <label>confirm password</label>
                                            <input type="password" name="cpassword" value={formData.cpassword} onChange={handleInputChange} />
                                        </div> */}
                                        <div className="col-md-12 text-start">
                                            <label>confirm password</label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" name="password" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mt-md-3 mt-lg-3 mt-xl-5">
                            <Link to='/homepage'><button className="btn submit-btn me-4">submit</button></Link>
                            <button className="btn submit-btn">close</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RegistrationForm;