import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunRightTable/FunRightTable.css'

const FunLeftTable = () => {

    return (
        <>
            <section className="fun-right-table">
                <div className=''>
                    <div className='main-left-table mt-4'>
                        {/* First Row */}
                        <div className="common-button-main">
                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Pink.png"} className="single-img" alt="..." />
                            <div className='sub-common-button'>
                                <button className="first-line-button">TRIPLES</button>
                            </div>
                        </div>
                        <div className='right-triple-table'>
                            <div className='left-empty-table-block d-flex'>
                                <div className='last-empty-brown'>09</div>
                                <div className='empty-brown'>000</div>
                                <div className='empty-brown'>100</div>
                                <div className='empty-brown'>200</div>
                                <div className='empty-brown'>300</div>
                                <div className='empty-brown'>400</div>
                                <div className='empty-brown'>500</div>
                                <div className='empty-brown'>600</div>
                                <div className='empty-brown'>700</div>
                                <div className='empty-brown'>800</div>
                                <div className='empty-brown'>900</div>
                            </div>
                            {/* First Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='brown-bg'>000</div>
                                <div className='yellow-bg'>001</div>
                                <div className='brown-bg'>002</div>
                                <div className='yellow-bg'>003</div>
                                <div className='brown-bg'>004</div>
                                <div className='yellow-bg'>005</div>
                                <div className='brown-bg'>006</div>
                                <div className='yellow-bg'>007</div>
                                <div className='brown-bg'>008</div>
                                <div className='yellow-bg'>009</div>
                            </div>
                            {/* Second Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='yellow-bg'>010</div>
                                <div className='brown-bg'>011</div>
                                <div className='yellow-bg'>012</div>
                                <div className='brown-bg'>013</div>
                                <div className='yellow-bg'>014</div>
                                <div className='brown-bg'>015</div>
                                <div className='yellow-bg'>016</div>
                                <div className='brown-bg'>017</div>
                                <div className='yellow-bg'>018</div>
                                <div className='brown-bg'>019</div>
                            </div>
                            {/* Third Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='brown-bg'>020</div>
                                <div className='yellow-bg'>021</div>
                                <div className='brown-bg'>022</div>
                                <div className='yellow-bg'>023</div>
                                <div className='brown-bg'>024</div>
                                <div className='yellow-bg'>025</div>
                                <div className='brown-bg'>026</div>
                                <div className='yellow-bg'>027</div>
                                <div className='brown-bg'>028</div>
                                <div className='yellow-bg'>029</div>
                            </div>
                            {/* Fourth Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='yellow-bg'>030</div>
                                <div className='brown-bg'>031</div>
                                <div className='yellow-bg'>032</div>
                                <div className='brown-bg'>033</div>
                                <div className='yellow-bg'>034</div>
                                <div className='brown-bg'>035</div>
                                <div className='yellow-bg'>036</div>
                                <div className='brown-bg'>037</div>
                                <div className='yellow-bg'>038</div>
                                <div className='brown-bg'>039</div>
                            </div>
                            {/* Five Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='brown-bg'>040</div>
                                <div className='yellow-bg'>041</div>
                                <div className='brown-bg'>042</div>
                                <div className='yellow-bg'>043</div>
                                <div className='brown-bg'>044</div>
                                <div className='yellow-bg'>045</div>
                                <div className='brown-bg'>046</div>
                                <div className='yellow-bg'>047</div>
                                <div className='brown-bg'>048</div>
                                <div className='yellow-bg'>049</div>
                            </div>
                            {/* Six Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='yellow-bg'>050</div>
                                <div className='brown-bg'>051</div>
                                <div className='yellow-bg'>052</div>
                                <div className='brown-bg'>053</div>
                                <div className='yellow-bg'>054</div>
                                <div className='brown-bg'>055</div>
                                <div className='yellow-bg'>056</div>
                                <div className='brown-bg'>057</div>
                                <div className='yellow-bg'>058</div>
                                <div className='brown-bg'>059</div>
                            </div>
                            {/* Seven Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='brown-bg'>060</div>
                                <div className='yellow-bg'>061</div>
                                <div className='brown-bg'>062</div>
                                <div className='yellow-bg'>063</div>
                                <div className='brown-bg'>064</div>
                                <div className='yellow-bg'>065</div>
                                <div className='brown-bg'>066</div>
                                <div className='yellow-bg'>066</div>
                                <div className='brown-bg'>068</div>
                                <div className='yellow-bg'>069</div>
                            </div>
                            {/* Eight Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='yellow-bg'>070</div>
                                <div className='brown-bg'>071</div>
                                <div className='yellow-bg'>072</div>
                                <div className='brown-bg'>073</div>
                                <div className='yellow-bg'>074</div>
                                <div className='brown-bg'>075</div>
                                <div className='yellow-bg'>076</div>
                                <div className='brown-bg'>077</div>
                                <div className='yellow-bg'>077</div>
                                <div className='brown-bg'>079</div>
                            </div>
                            {/* Nine Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='brown-bg'>080</div>
                                <div className='yellow-bg'>081</div>
                                <div className='brown-bg'>082</div>
                                <div className='yellow-bg'>083</div>
                                <div className='brown-bg'>084</div>
                                <div className='yellow-bg'>085</div>
                                <div className='brown-bg'>086</div>
                                <div className='yellow-bg'>087</div>
                                <div className='brown-bg'>088</div>
                                <div className='yellow-bg'>089</div>
                            </div>
                            {/* Ten Row */}
                            <div className='table-block d-flex'>
                                <div className='left-brown-bg-tringle'>
                                    <div class="arrow-left"></div>
                                </div>
                                <div className='yellow-bg'>090</div>
                                <div className='brown-bg'>091</div>
                                <div className='yellow-bg'>092</div>
                                <div className='brown-bg'>093</div>
                                <div className='yellow-bg'>094</div>
                                <div className='brown-bg'>095</div>
                                <div className='yellow-bg'>096</div>
                                <div className='brown-bg'>097</div>
                                <div className='yellow-bg'>098</div>
                                <div className='brown-bg'>099</div>
                            </div>
                            {/* Eleven Row */}
                            <div className='table-block d-flex'>
                                <div className='last-empty-brown'>09</div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                                <div className='down-brown-bg-tringle'>
                                    <div class="arrow-down"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunLeftTable;