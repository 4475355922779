import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunTripleText/FunTripleText.css'

const FunTripleText = () => {

    return (
        <>
            <section className="fun-triple-text">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='fun-triple-title mt-3'>
                                <h5>triple fun</h5>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <p className='score-text'>SCORE</p>
                            <div className="first-line-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow.png"} className="yellow-img" alt="..." />
                                <div className='first-line-button-text'>
                                    <button className="first-line-button">0.00</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className="first-line-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow 2.png"} className="yellow-middle-img" alt="..." />
                                <div className='first-line-button-middle-text'>
                                    <button className="first-line-button">0.1:53</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <p className='score-text'>WINNER</p>
                            <div className="first-line-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow.png"} className="yellow-img" alt="..." />
                                <div className='first-line-button-text'>
                                    <button className="first-line-button">0</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunTripleText;