import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../MainTripleFun/MainTripleFun.css'
import FunTripleText from '../FunTripleText/FunTripleText.js';
import FunSpinner from '../../FunRoulette/FunSpinner/FunSpinner';
import FunRightTable from '../FunRightTable/FunRightTable';
import FunLeftTable from '../FunRightTable/FunLeftTable';
import FunTripleButton from '../FunTripleButton/FunTripleButton.js';


const MainTripleFun = () => {
    return (
        <>
            <section className="main-triple-fun">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <FunTripleText />
                        </div>
                        <div className='col-md-4'>
                            <FunRightTable />
                        </div>
                        <div className='col-md-4'>
                            <div className='triple-fun-game'>
                                <FunSpinner />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <FunLeftTable />
                        </div>
                        <div className='col-md-12'>
                            <FunTripleButton />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainTripleFun;