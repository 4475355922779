import React, { useEffect } from 'react';
import BackGround from '../LoginPage/BackGround/BackGround';
import MainHome from './MainHome/MainHome';
import { FullScreen, useFullScreenHandle } from "react-full-screen";


const HomePage = () => {
    const handle = useFullScreenHandle();

    useEffect(() => {
        handle.enter();
    }, [])

    return (
        <FullScreen handle={handle}>
            <div>
                <BackGround />
                <MainHome />
            </div>
        </FullScreen>
    )
}

export default HomePage;