import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunRightTable/FunRightTable.css'


const FunRightTable = () => {

    return (
        <>
            <section className="fun-right-table">
                <div className=''>
                    <div className='main-left-table mt-4'>
                        {/* First Row */}
                        <div className="common-button-main">
                            <div className='double-bg-img'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Pink.png"} className="single-img" alt="..." />
                            </div>
                            <div className='sub-common-button'>
                                <button className="first-line-button">DOUBLES</button>
                            </div>
                        </div>
                        <div className='empty-table-block d-flex'>
                            <div className='empty-brown'>00</div>
                            <div className='empty-brown'>01</div>
                            <div className='empty-brown'>02</div>
                            <div className='empty-brown'>03</div>
                            <div className='empty-brown'>04</div>
                            <div className='empty-brown'>05</div>
                            <div className='empty-brown'>06</div>
                            <div className='empty-brown'>07</div>
                            <div className='empty-brown'>08</div>
                            <div className='empty-brown'>09</div>
                            <div className='last-empty-brown'>09</div>
                        </div>
                        {/* First Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg'>00</div>
                            <div className='yellow-bg'>01</div>
                            <div className='brown-bg'>02</div>
                            <div className='yellow-bg'>03</div>
                            <div className='brown-bg'>04</div>
                            <div className='yellow-bg'>05</div>
                            <div className='brown-bg'>06</div>
                            <div className='yellow-bg'>07</div>
                            <div className='brown-bg'>08</div>
                            <div className='yellow-bg'>09</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Second Row */}
                        <div className='table-block d-flex'>
                            <div className='yellow-bg'>10</div>
                            <div className='brown-bg'>11</div>
                            <div className='yellow-bg'>12</div>
                            <div className='brown-bg'>13</div>
                            <div className='yellow-bg'>14</div>
                            <div className='brown-bg'>15</div>
                            <div className='yellow-bg'>16</div>
                            <div className='brown-bg'>17</div>
                            <div className='yellow-bg'>18</div>
                            <div className='brown-bg'>19</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Third Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg'>20</div>
                            <div className='yellow-bg'>21</div>
                            <div className='brown-bg'>22</div>
                            <div className='yellow-bg'>23</div>
                            <div className='brown-bg'>24</div>
                            <div className='yellow-bg'>25</div>
                            <div className='brown-bg'>26</div>
                            <div className='yellow-bg'>27</div>
                            <div className='brown-bg'>28</div>
                            <div className='yellow-bg'>29</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Fourth Row */}
                        <div className='table-block d-flex'>
                            <div className='yellow-bg'>30</div>
                            <div className='brown-bg'>31</div>
                            <div className='yellow-bg'>32</div>
                            <div className='brown-bg'>33</div>
                            <div className='yellow-bg'>34</div>
                            <div className='brown-bg'>35</div>
                            <div className='yellow-bg'>36</div>
                            <div className='brown-bg'>37</div>
                            <div className='yellow-bg'>38</div>
                            <div className='brown-bg'>39</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Five Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg'>40</div>
                            <div className='yellow-bg'>41</div>
                            <div className='brown-bg'>42</div>
                            <div className='yellow-bg'>43</div>
                            <div className='brown-bg'>44</div>
                            <div className='yellow-bg'>45</div>
                            <div className='brown-bg'>46</div>
                            <div className='yellow-bg'>47</div>
                            <div className='brown-bg'>48</div>
                            <div className='yellow-bg'>49</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Six Row */}
                        <div className='table-block d-flex'>
                            <div className='yellow-bg'>50</div>
                            <div className='brown-bg'>51</div>
                            <div className='yellow-bg'>52</div>
                            <div className='brown-bg'>53</div>
                            <div className='yellow-bg'>54</div>
                            <div className='brown-bg'>55</div>
                            <div className='yellow-bg'>55</div>
                            <div className='brown-bg'>57</div>
                            <div className='yellow-bg'>58</div>
                            <div className='brown-bg'>59</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Seven Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg'>60</div>
                            <div className='yellow-bg'>61</div>
                            <div className='brown-bg'>62</div>
                            <div className='yellow-bg'>63</div>
                            <div className='brown-bg'>64</div>
                            <div className='yellow-bg'>65</div>
                            <div className='brown-bg'>66</div>
                            <div className='yellow-bg'>66</div>
                            <div className='brown-bg'>68</div>
                            <div className='yellow-bg'>69</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Eight Row */}
                        <div className='table-block d-flex'>
                            <div className='yellow-bg'>70</div>
                            <div className='brown-bg'>71</div>
                            <div className='yellow-bg'>72</div>
                            <div className='brown-bg'>73</div>
                            <div className='yellow-bg'>74</div>
                            <div className='brown-bg'>75</div>
                            <div className='yellow-bg'>76</div>
                            <div className='brown-bg'>77</div>
                            <div className='yellow-bg'>77</div>
                            <div className='brown-bg'>79</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Nine Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg'>80</div>
                            <div className='yellow-bg'>81</div>
                            <div className='brown-bg'>82</div>
                            <div className='yellow-bg'>83</div>
                            <div className='brown-bg'>84</div>
                            <div className='yellow-bg'>85</div>
                            <div className='brown-bg'>86</div>
                            <div className='yellow-bg'>87</div>
                            <div className='brown-bg'>88</div>
                            <div className='yellow-bg'>89</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Ten Row */}
                        <div className='table-block d-flex'>
                            <div className='yellow-bg'>90</div>
                            <div className='brown-bg'>91</div>
                            <div className='yellow-bg'>92</div>
                            <div className='brown-bg'>93</div>
                            <div className='yellow-bg'>94</div>
                            <div className='brown-bg'>95</div>
                            <div className='yellow-bg'>96</div>
                            <div className='brown-bg'>97</div>
                            <div className='yellow-bg'>98</div>
                            <div className='brown-bg'>99</div>
                            <div className='right-brown-bg-tringle'>
                                <div className="arrow-right"></div>
                            </div>
                        </div>
                        {/* Eleven Row */}
                        <div className='table-block d-flex'>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='brown-bg-tringle'>
                                <div class="arrow-down"></div>
                            </div>
                            <div className='last-empty-brown'>09</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunRightTable;