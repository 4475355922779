import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import '../../FunRoulette/FunSpinner/FunSpinner.css'


const ReactSpinner = () => {

  const data = [
    { option: '0' },
    { option: '' },
    { option: '1' },
    { option: '' },
    { option: '2' },
    { option: '' },
    { option: '3' },
    { option: '' },
    { option: '4' },
    { option: '' },
    { option: '5' },
    { option: '' },
    { option: '6' },
    { option: '' },
    { option: '7' },
    { option: '' },
    { option: '8' },
    { option: '' },
    { option: '9' },
    { option: '' },
  ];

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  return (
    <>
      <div className='col-md-12'>
        <div className='spinner'>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            backgroundColors={['#EA5A1D', '#E2CFCF24']}
            textColors={['#FFFFFF']}
            innerRadius={[30]}
            innerBorderWidth={12}
            innerBorderColor={['#FFFF4B']}
            outerBorderWidth={[6]}
            outerBorderColor={['#6E0000']}
            spinDuration={[1]}
            fontSize={50}
            fontWeight={['bold']}
            onStopSpinning={() => {
              setMustSpin(false);
            }}
          />
          {/* <div className='tripe-img-main'>
            <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/casino-_-circle (1).png"} className="tripe-img" alt="..." />
          </div> */}
        </div>
      </div>
      <div className='col-md-12 text-center'>
        <div className="common-button-main">
          <div className='sub-common-button'>
            <button className="first-line-button" onClick={handleSpinClick} >spin</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReactSpinner;



