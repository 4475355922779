import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunTripleButton/FunTripleButton.css'

const FunTripleButton = () => {
    return (
        <>
            <section className="fun-triple-button">
                <div className=''>
                    {/* First Row */}
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='upper-img'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/d-1.png"} className="upper-design-img" alt="..." />
                            </div>
                            <div className='blue-bg d-flex'>
                                <div className='last-number'>LAST 5 = 54</div>
                                <div className='number-class'>84</div>
                                <div className='number-class'>95</div>
                                <div className='number-class'>80</div>
                                <div className='number-class'>65</div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4'>
                            <div className="common-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Pink.png"} className="single-img" alt="..." />
                                <div className='sub-common-button'>
                                    <button className="first-line-button">singles</button>
                                </div>
                            </div>
                            <div className='number-line d-flex mx-auto'>
                                <div className='brown-bg'>0</div>
                                <div className='yellow-bg'>1</div>
                                <div className='brown-bg'>2</div>
                                <div className='yellow-bg' >3</div>
                                <div className='brown-bg'>4</div>
                                <div className='yellow-bg'>5</div>
                                <div className='brown-bg'>6</div>
                                <div className='yellow-bg'>7</div>
                                <div className='brown-bg'>8</div>
                                <div className='yellow-bg'>9</div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='upper-img'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/d-1.png"} className="upper-design-img" alt="..." />
                            </div>
                            <div className='blue-bg d-flex'>
                                <div className='last-number'>LAST 5 = 54</div>
                                <div className='number-class'>84</div>
                                <div className='number-class'>95</div>
                                <div className='number-class'>80</div>
                                <div className='number-class'>65</div>
                            </div>
                        </div>
                        <div className='col-md-4 mt-5 text-end'>
                            <div className="common-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow 3.png"} className="single-img" alt="..." />
                                <div className='single-common-button'>
                                    <button className="first-line-button">CANCEL</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='upper-img mt-3'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/d-1.png"} className="upper-design-img" alt="..." />
                            </div>
                            <div className='blue-bg d-flex justify-content-center'>
                                <div className='last-number'>LAST 5 = 54</div>
                                <div className='number-class'>84</div>
                                <div className='number-class'>95</div>
                                <div className='number-class'>80</div>
                                <div className='number-class'>65</div>
                            </div>
                        </div>
                    </div>
                    {/* Second Row */}
                    <div className='row'>
                        <div className='col-md-3 text-start'>
                            <div className="common-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow 3.png"} className="single-img" alt="..." />
                                <div className='take-common-button'>
                                    <button className="first-line-button">Take</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='brown-bg-round'>
                                <div className='row'>
                                    <div className='col-md-10 mx-auto d-flex'>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 1.png"} className="digit-img" alt="..." />
                                            <div className='first-digit-class'>
                                                <p className="digit-round">1</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 5.png"} className="digit-img" alt="..." />
                                            <div className='first-digit-class'>
                                                <p className="digit-round">5</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 10.png"} className="digit-img" alt="..." />
                                            <div className='two-digit-class'>
                                                <p className="digit-round">10</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 50.png"} className="digit-img" alt="..." />
                                            <div className='two-digit-class'>
                                                <p className="digit-round">50</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 100.png"} className="digit-img" alt="..." />
                                            <div className='third-digit-class'>
                                                <p className="digit-round">100</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit  500.png"} className="digit-img" alt="..." />
                                            <div className='third-digit-class'>
                                                <p className="digit-round">500</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit  1000.png"} className="digit-img" alt="..." />
                                            <div className='four-digit-class'>
                                                <p className="digit-round">1000</p>
                                            </div>
                                        </div>
                                        <div className="digit-round-main me-3">
                                            <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Digit 5000.png"} className="digit-img" alt="..." />
                                            <div className='four-digit-class'>
                                                <p className="digit-round">5000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 text-end'>
                            <div className="common-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/Yellow 3.png"} className="single-img" alt="..." />
                                <div className='bet-ok-button'>
                                    <button className="first-line-button">Bet ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Third Row */}
                    <div className='row'>
                        <div className='col-md-1'>
                            <div className='zero-button-class'>
                                <button className='zero-button'>0</button>
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className="common-button-main">
                                <img src={process.env.PUBLIC_URL + "assets/Image/tripal-fun/baryellow.png"} className="last-bar-img" alt="..." />
                                <div className='please-button-class'>
                                    <button className='first-line-button'>Please Bet to Start Game_Minimum Bet = 1</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className='exit-button-class'>
                                <button className='exit-button'>exit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunTripleButton;