import React from 'react'
import MainTripleFun from './MainTripleFun/MainTripleFun';



const FunTriple = () => {
    return (
        <div>
            {/* <FunTripleText /> */}
            {/* <FunTripleButton /> */}
            <MainTripleFun/>
        </div>
    )
}

export default FunTriple;