import React  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../MainHome/MainHome.css'
import CheckAuthontiCity from '../CheckAuthontiCity/CheckAuthontiCity';
import Game from '../Game/Game';
import HomeButton from '../HomeButton/HomeButton';

const MainHome = () => {
    return (
        <>
            <section className="main-home">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <CheckAuthontiCity />
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <Game/>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <HomeButton />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainHome;