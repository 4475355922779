import React, { useEffect } from 'react';
import BackGround from '../LoginPage/BackGround/BackGround.js';
import RegistrationForm from './RegistrationForm/RegistrationForm.js';
import { FullScreen, useFullScreenHandle } from "react-full-screen";



const Registration = () => {
    const handle = useFullScreenHandle();

    useEffect(() => {
        handle.enter();
    }, [])

    return (
        <FullScreen handle={handle}>
            <div>
                <BackGround />
                <RegistrationForm />
            </div>
        </FullScreen>

    )
}

export default Registration;