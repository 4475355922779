import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunTable/FunTable.css'

const FunTable = () => {
    return (
        <>
            <section className="fun-table">
                <div className='contanier'>
                    {/* First row */}
                    <div className='row g-0 mt-5'>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                    </div>

                    {/* Second row */}
                    <div className='row g-0'>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                    </div>

                    {/* Third row */}
                    <div className='row g-0'>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                        <div className='col-md-1 yellow-border'>
                            <p className='red-bg'>3</p>
                        </div>
                        <div className='col-md-1 yellow-border' >
                            <p className='green-bg'>6</p>
                        </div>
                    </div>

                    {/* Fourth row */}
                    <div className='sub-table'>
                        <table>
                            <tr>
                                <th colspan="2">1st 12</th>
                                <th colspan="2">2nd 12</th>
                                <th colspan="2">3rd 12</th>
                            </tr>
                            <tr>
                                <td>1 TO 18</td>
                                <td>EVEN</td>
                                <td><img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/Polygon 1.png"} className="polygon-img" alt="..." /></td>
                                <td><img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/Polygon 2.png"} className="polygon-img" alt="..." /></td>
                                <td>ODD</td>
                                <td>$100</td>
                            </tr>
                        </table>
                    </div>

                    {/* Fifth row */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='text-center'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/lst-line.png"} className="lastline-img" alt="..." />
                            </div>
                        </div>
                    </div>

                    {/* Sixth row */}
                    <div className='row mt-4'>
                        <div className='col-md-1'>
                            <div className='last-btn text-start'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-left-bg.png"} className="left-button-img" alt="..." />
                                <div className='back-btn-main'>
                                    <button className='btn back-btn-class'></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-10'>
                            <div className='text-center mt-2'>
                                <p className='minimum-text'>YOUR MINIMUM BET IS = 1</p>
                            </div>
                        </div>
                        <div className='col-md-1'>
                            <div className='last-btn text-start'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/button-right-bg.png"} className="left-button-img" alt="..." />
                                <div className='back-btn-main'>
                                    <button className='btn back-btn-class'>exit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunTable;