import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Game/Game.css'
import { Link } from 'react-router-dom';


const Game = () => {
    return (
        <>
            <section className="game">
                <div className='game-img-main'>
                    <div className='mt-5'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/homepage/games.png"} className="game-img mt-4" alt="..." />
                        <div className='games-title'>
                            <p>Games</p>
                        </div>
                        <div className='game-name-main'>
                            <div className='text-holder-game ms-3'>
                                <div className=' mt-xxl-5'>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <p><a>millionaire</a></p>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <p><a>bingo</a></p>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <p><a>fun ab</a></p>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <p><a>lucky 8 line</a></p>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <Link to='/fun-darbhar'><p><a>funandarbahar</a></p></Link>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <Link to='/fun-target'><p>fun target</p></Link>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <Link to='/fun-roulette'><p>fun roulette</p></Link>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <p><a>BING</a></p>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='gamed-list d-flex justify-content-between'>
                                        <Link to='/fun-triple'><p><a>Triple Fun</a></p></Link>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/homepage/Close_btn.png"} className="red-dot-img" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Game;