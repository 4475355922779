import React from "react";
import '../BackGround/BackGround.css'
import BackGroundGlow from "../Animation/BackGroundGlow";

const BackGround = () => {
    return (
        <>
            <section className="background">
                <div>
                    <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/background/mainbg.png"} className="main-banner-img" alt="..." />
                </div>
                <div className="subbackground">
                    <div>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/background/sub-bg.png"} className="banner-img" alt="..." />
                    </div>
                    <div className='dimond-img-main'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Diamond_big.png"} className="diamond-img" alt="..." />
                        <div className="bg-glow">
                            <BackGroundGlow />
                        </div>
                    </div>
                    <div className='dimond-img-small'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Diamond_small.png"} className="diamond-left-top" alt="..." />
                        <div className="left-top-bg-glow">
                            <BackGroundGlow />
                        </div>
                    </div>
                    <div className='dimond-left-bottom'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Diamond_small.png"} className="diamond-left-top" alt="..." />
                        <div className="left-top-bg-glow">
                            <BackGroundGlow />
                        </div>
                    </div>
                    <div className='dimond-right-top'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Diamond_small.png"} className="diamond-left-top" alt="..." />
                        <div className="left-top-bg-glow">
                            <BackGroundGlow />
                        </div>
                    </div>
                    <div className='dimond-right-bottom'>
                        <img src={process.env.PUBLIC_URL + "assets/Image/loginpage/loginpage/Diamond_small.png"} className="diamond-left-top" alt="..." />
                        <div className="left-top-bg-glow">
                            <BackGroundGlow />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BackGround;