import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../FunMain/FunMain.css'
import FunGame from '../FunGame/FunGame';
import FunTable from '../FunTable/FunTable';

const FunMain = () => {
    return (
        <>
            <section className="fun-main">
                <div className='contanier-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="funmain-bg">
                                <div className="fun-sub-bg">
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-roulette/fun-bg.png"} className="funbg-img" alt="..." />
                                </div>
                                <div className='fun-text-holder-main'>
                                    <div className='funmain-text'>
                                        <FunGame />
                                    </div>
                                    <div className='funmain-table ms-5 me-5'>
                                        <FunTable />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FunMain;