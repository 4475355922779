import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../HomeButton/HomeButton.css'


const HomeButton = () => {
    return (
        <>
            <section className="home-button">
                <div className='row mt-5'>
                    <div className='col-md-12'>
                        <div className='logged-id' >
                            <div className='logged-id-img' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/logged.png"} className="log-img" alt="..." />
                            </div>
                            <div className='text-holder-logged'>
                                <p>Logged id :-</p>
                                <div className='white-line'></div>
                            </div>
                            <div className='text-holder-points'>
                                <p>Points :-</p>
                                <div className='white-line'></div>
                            </div>
                        </div>
                        <div className='gk-point-main' >
                            <div className='gk-point-img' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/select.png"} className="gk-img" alt="..." />
                            </div>
                            <div className='empty-circle'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/empty-circle.png"} className="empty-circle-img" alt="..." />
                            </div>
                            <div className='text-holder-gk'>
                                <p>Gk point</p>
                            </div>
                        </div>
                        <div className='gk-point-main' >
                            <div className='gk-point-img' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/select.png"} className="gk-img" alt="..." />
                            </div>
                            <div className='empty-circle'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/check-mark.png"} className="empty-circle-img" alt="..." />
                            </div>
                            <div className='text-holder-gk'>
                                <p>Multiplayer Points</p>
                            </div>
                        </div>
                        <div className='point-transfer-main mt-md-3 mt-xl-5' >
                            <div className='point-transfer' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/bg-img.png"} className="bg-img" alt="..." />
                            </div>
                            <div className='shape-box'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/love.png"} className="shape-img" alt="..." />
                            </div>
                            <div className='text-holder-pointer'>
                                <p>Point Transfer</p>
                            </div>
                        </div>
                        <div className='point-transfer-main mt-md-3 mt-xl-5' >
                            <div className='point-transfer' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/bg-img.png"} className="bg-img" alt="..." />
                            </div>
                            <div className='shape-box'>
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/plus.png"} className="shape-img" alt="..." />
                            </div>
                            <div className='text-holder-pointer'>
                                <p>Child Transfer</p>
                            </div>
                        </div>
                        <div className='setting-main mt-5 mt-md-2 mt-lg-5' >
                            <div className='setting-img-main' >
                                <img src={process.env.PUBLIC_URL + "assets/Image/homepage/setting.png"} className="setting-bg-img" alt="..." />
                            </div>
                            <div className='text-holder-setting'>
                                <p><img src={process.env.PUBLIC_URL + "assets/Image/homepage/setting-circle.png"} className="setting-img me-3" alt="..." /> Setting</p>
                            </div>
                        </div>
                        <div className='mt-md-2 mt-xl-5'>
                            <button className='btn refresh-btn'><img src={process.env.PUBLIC_URL + "assets/Image/homepage/refresh.png"} className="refresh-img me-3" alt="..." /> Refresh</button>
                            <button className='btn refresh-btn'><img src={process.env.PUBLIC_URL + "assets/Image/homepage/Logout.png"} className="refresh-img me-3" alt="..." /> Logout</button>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default HomeButton;