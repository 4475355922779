import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FunTargetText.css'
import BackGroundGlow from '../../LoginPage/Animation/BackGroundGlow';
import { Link } from 'react-router-dom';
import TarSpinner from '../TarSpinner/TarSpinner';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const FunTargetText = () => {
    const handle = useFullScreenHandle();

    useEffect(() => {
        handle.enter();
    }, [])
    return (
        <>
            <FullScreen handle={handle}>
                <section className="fun-target-text">
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-3 ps-0'>
                                <p className='text-target'>score</p>
                                <div className='text-bg-main'>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/text.png"} className="text-bg-img" alt="..." />
                                    <div className='number-text'>
                                        <p>0.00</p>
                                    </div>
                                </div>
                                <div className='mt-5 mt-md-2'>
                                    <p className='text-target'>time</p>
                                    <div className='text-bg-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/text.png"} className="text-bg-img" alt="..." />
                                        <div className='number-text'>
                                            <p>0.00</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='brown-bg p-2'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/green round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>1</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/pink-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>50</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/yellow-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>10</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/purple-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>50</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='mt-3'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/casino.png"} className="fun-target-img" alt="..." /> */}
                                    <TarSpinner />
                                </div>
                            </div>
                            <div className='col-md-3 pe-0'>
                                <div>
                                    <p className='text-target'>WINNER</p>
                                    <div className='text-bg-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/text.png"} className="text-bg-img" alt="..." />
                                        <div className='number-text'>
                                            <p>0</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-target'>last 10 data</p>
                                    <div className='text-bg-main'>
                                        <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/text.png"} className="text-bg-img" alt="..." />
                                        <div className='number-text-seris d-flex'>
                                            <p className='me-3 me-md-2'>9</p>
                                            <p className='me-3 me-md-2'>6</p>
                                            <p className='me-3 me-md-2'>0</p>
                                            <p className='me-3 me-md-2'>6 </p>
                                            <p className='me-3 me-md-2'>5</p>
                                            <p className='me-3 me-md-2'>7</p>
                                            <p className='me-3 me-md-2'>1</p>
                                            <p className='me-3 me-md-2'>8</p>
                                            <p className='me-3 me-md-2'>5</p>
                                            <p className='me-3 me-md-2'>3</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='brown-bg-right p-2'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/green round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>100</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/pink-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/yellow-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>100</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='round-main'>
                                                <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/purple-round.png"} className="round-img" alt="..." />
                                                <div className='text-round'>
                                                    <p>500</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='yellow-main'>
                                    <div className='yellow-strip'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/fun target 2.png"} className="yellow-strip-img" alt="..." />
                                        </div>
                                        <div className='title-fun-target'>
                                            <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/title.png"} className="title-img" alt="..." />
                                        </div>
                                        <div className='dimond-main-first'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-second'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-third'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-four'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-five'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-six'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-seven'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='dimond-main-eight'>
                                            <div className='title-dimond-glow'>
                                                {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/Diamond_1.png"} className="dimond-img" alt="..." /> */}
                                                <div className="triple-bg-glow">
                                                    <BackGroundGlow />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-md-6 ps-0'>
                                <div className='text-start'>
                                    <button className='btn take-btn me-5'>take</button>
                                    <button className='btn cancel-btn'>cancel bet</button>
                                </div>
                            </div>
                            <div className='col-md-6 pe-0'>
                                <div className='text-end'>
                                    <button className='btn bet-btn'>bet ok</button>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-1'></div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>1</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>2</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>3</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>4</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>5</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>6</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>7</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>8</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>9</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <div className='red-round'>
                                    {/* <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/empty-bg.png"} className="empty-bg-img" alt="..." /> */}
                                    <div className="input-group">
                                        <input type="number" className="form-control" name="accountnumber" />
                                    </div>
                                    <img src={process.env.PUBLIC_URL + "assets/Image/fun-target/red-round.png"} className="red-img" alt="..." />
                                    <div className='one'>
                                        <p>0</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-2 ps-0'>
                                <div className='text-start'>
                                    <button className='btn empty-btn'>take</button>
                                </div>
                            </div>
                            <div className='col-md-8 pb-0'>
                                <div className='yellow-game-bg text-center mt-2'>
                                    <button className='game-over-btn'>Game Over</button>
                                </div>
                            </div>
                            <div className='col-md-2 pe-0'>
                                <div className='text-end'>
                                    <Link to='/homepage'><button className='btn exit-btn'>exit</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FullScreen>
        </>
    )
}

export default FunTargetText;